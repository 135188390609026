export default {
  "client": "Bill & Melinda Gates Foundation, LDC",
  "bannerImage": require("../../../img/mocks/ldc_hero.png"),
  "productImageSmall": require("../../../img/mocks/ldc-small.png"),
  "product": "Jurying Tool",
  "services": [
    "Product Design",
    "Project Management",
    "Web Development"
  ],
  "problemsSolved": [],
  "timeLine": [
    {
      "date": "November 2012",
      "summary": "Project kick off",
      "icon": "hourglass-start",
      "iconColor": "gray"
    },
    {
      "date": "December 2012",
      "summary": "Remote user feedback",
      "icon": "comments",
      "iconColor": "blue"
    },
    {
      "date": "May 2013",
      "summary": "First jurying session with new tool",
      "icon": "gavel",
      "iconColor": "green"
    },
    {
      "date": "December 2013",
      "summary": "Transition to CoreTools module authoring",
      "icon": "pencil",
      "iconColor": "dark"
    },
    {
      "date": "January 2015",
      "summary": "Professional development incorporated into CoreTools",
      "icon": "book",
      "iconColor": "red"
    }
  ]
}
